import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { useMediaQuery } from "@material-ui/core";

function ArticlePreview({ children, title, dateAndAuthor, href, image }) {
  const small = useMediaQuery("(max-width: 600px)");
  const truncatedText =
    children
      .split(/\s+/)
      .slice(0, small ? 20 : 40)
      .join(" ") + "...";
  return (
    <div class="blogpost">
      <h2>
        <Link to={href}>{title}</Link>
      </h2>
      <p class="dateAndAuthor">{dateAndAuthor}</p>
      <p>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1.45rem",
          }}
        >
          <Img
            style={{ width: "80%", height: "80%" }}
            {...image.childImageSharp}
          />
        </div>
        {truncatedText} <Link to={href}>Read more</Link>
      </p>
    </div>
  );
}

const Blog = () => {
  const imagedata = useStaticQuery(graphql`
    query {
      coachSpace: file(
        relativePath: { eq: "pexels-the-coach-space-2977565.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personWithFloorPlan: file(
        relativePath: { eq: "pexels-karolina-grabowska-4491475.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      balconies: file(relativePath: { eq: "balconies-1031241_1920.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hotdog: file(relativePath: { eq: "hot-dog-2436748_1920.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout maxWidth={600}>
      <SEO 
      title="Blog post list" 
      description="Read more about the unique tech used at PanoRoom and our influence on the Real Estate Market."
      // image={imagedata.personWithFloorPlan} 
      />

      <div style={{ padding: "0px 1em" }}>
        <ArticlePreview
          title="What does make PanoRoom unique among floor plan apps?"
          dateAndAuthor="Piotr Wierzba, 1 Dec 2020"
          href="/2020-12-01-panoroom-unique/"
          image={imagedata.coachSpace}
        >
          There are tens of floor plan apps on the market to choose from. Just
          open the AppStore and type "floor plan" (go ahead, try it!). Likely,
          you will see an endless feed filled with look-alike app icons. When we
          were creating PanoRoom, we tried many of them in pursuit of creating a
          software which is faster, more precise, and more intuitive to use. So,
          no matter whether you are a realtor or a casual user, chances are
          PanoRoom will be a great fit for you.
        </ArticlePreview>

        <ArticlePreview
          title="How to increase your revenue with floor plans: a practical guide."
          dateAndAuthor="Piotr Wierzba, 1 Dec 2020"
          href="/2020-12-01-realtors-advantages/"
          image={imagedata.personWithFloorPlan}
        >
          Floor plans are a crutial feature for any real estate listing. Full
          stop. They present features that can't be easily viewed in a picture
          (such as depth of a wardrobe), and to unequivocally determine size &
          layout of the apartment. More importantly, they are highly desired by
          clients and can increase the value of the sold property.
        </ArticlePreview>

        <ArticlePreview
          title="Realtors and floor plans: a story of a hard love."
          dateAndAuthor="Piotr Wierzba, 11 Jan 2020"
          href="/2020-01-11-realtors-and-floor-plans"
          image={imagedata.balconies}
        >
          Back in a day, when one wanted to buy a new home, they’d need to sort
          through columns of plain text listing properties only sparsely
          followed by a pixelated black and white picture. A brief description
          was enough to get the basic facts straight, like total size or the
          number of rooms. As you’d think, visiting a prospective future home
          felt more like going on a blind date, rather than just confirming what
          you already know about that property.
        </ArticlePreview>

        <ArticlePreview
          title="Furnishing: a study of an IKEA trip."
          dateAndAuthor="Piotr Wierzba, 11 Jan 2020"
          href="/2020-01-11-furnishing-ikea"
          image={imagedata.hotdog}
        >
          Let’s face it: everyone was at an IKEA at some point of their life.
          Whether as a toddler (so not entirely voluntarily), a kid (more so) or
          an adult looking for cheap furniture. The magnetic allure of the
          Swedish furnishing giant invariably leads to a maze filled with sofas,
          chairs and fruit-scented candles.
        </ArticlePreview>
      </div>
    </Layout>
  );
};

export default Blog;
